<template>
  <div>
    <a-modal
      v-model="isShow"
      centered
      :mask-closable="false"
      :ok-button-props="{ props: { loading: submitting } }"
      title="发送奠盒仪式提醒"
      @ok="handleSubmit"
    >
      <span>请确认并维护以下信息：</span>
      <a-form
        class="custom-compact-form"
        style="margin-top:30px;"
        :form="form"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 15 }"
        @submit="handleSubmit"
      >
        <a-form-item label="提醒内容">
          <a-textarea
            v-decorator="['content', {
              normalize: this.$lodash.trim,
              rules: [
                { required: true, message: '请输入提醒内容' },
                { max: 100, message: '最多100个字符' },
              ]
            }]"
          />
        </a-form-item>
        <a-form-item label="开始时间">
          <a-date-picker
            style="width: 100%;"
            format="YYYY-MM-DD HH:mm"
            @openChange="handleStartOpenChange"
            :show-time="{ format: 'HH:mm' }"
            v-decorator="['start_at', {
              rules: [
                { required: true, message: `请选择开始时间` }
              ]
            }]"
          />
        </a-form-item>
        <a-form-item label="结束时间">
          <a-date-picker
            style="width: 100%;"
            @openChange="handleEndOpenChange"
            format="YYYY-MM-DD HH:mm"
            :show-time="{ format: 'HH:mm' }"
            v-decorator="['end_at', {
              rules: [
                { required: true, message: `请选择结束时间` }
              ]
            }]"
          />
        </a-form-item>
        <a-form-item label="发送目标">
          <span>小程序用户</span>
          <a-select
            mode="multiple"
            placeholder="请选择工作人员"
            @load="loadingMinaUserOptions"
            v-decorator="['user_id']"
          >
            <a-select-option
              v-for="user in miniUserOptions"
              :key="user.id"
              :value="user.id"
            >
              {{ user.emp_name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="仪式负责人">
          <a-select
            v-decorator="['task_user_id', {
              initialValue: defaultUser.id > 0 ? defaultUser.id : undefined,
              rules: [
                { required: true, message: '请选择仪式负责人' },
              ]
            }]"
            @load="loadingUserOptions"
            @change="handlePhoneNumberChange"
          >
            <a-select-option
              v-for="user in userOptions"
              :key="user.id"
              :value="user.id"
            >
              {{ user.emp_name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="联系电话">
          <a-input
            :disabled="true"
            v-decorator="['phone_number',{
              initialValue: defaultUser.phone_number,
              normalize: this.$lodash.trim
            }]"
            autocomplete="off"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>

import { sendLibationBoxCeremonyNotice } from '@/api/order'
import { formatDateTimeMin } from '@/utils/time'
import { findTenantTaskUserOptions, findFuneralHomeOptions } from '@/api/user'

export default {
  name: 'SendLibationBoxCeremonyNotice',
  components: {
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    serviceOrderId: {
      type: Number,
      required: true
    },
    hallName: {
      type: String,
      required: true
    },
    deathName: {
      type: String,
      required: true
    },
    funeralHomeId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'send_libation_box_ceremony_notice' }),
      submitting: false,
      data: {},
      userOptions: [],
      defaultUser: {},
      endOpen: false,
      miniUserOptions: [],
      loadingMinaUserOptions: false,
      loadingUserOptions: false
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  created() {
    this.fetchUserOptions()
    this.fetchMiniUserOptions()
    this.fetchFormData()
  },
  methods: {
    handleStartOpenChange() {
      const fieldsValue = this.form.getFieldsValue()
      const startValue = fieldsValue.start_at
      const endValue = fieldsValue.end_at
      if (endValue && startValue) {
        if (endValue.valueOf() < startValue.valueOf()) {
          this.endOpen = false
          this.$warning({
            title: '结束时间不能小于开始时间',
            content: ''
          })
          this.form.setFieldsValue({ start_at: null })
        }
      }
    },
    handleEndOpenChange() {
      const fieldsValue = this.form.getFieldsValue()
      const startValue = fieldsValue.start_at
      const endValue = fieldsValue.end_at
      if (endValue && startValue) {
        if (endValue.valueOf() < startValue.valueOf()) {
          this.endOpen = false
          this.$warning({
            title: '结束时间不能小于开始时间',
            content: ''
          })
          this.form.setFieldsValue({ end_at: null })
        }
      }
    },
    fetchFormData() {
      this.$nextTick(() => {
        this.form.setFieldsValue({ content: this.hallName + '(逝者姓名：' + this.deathName + ')，请家属与工作人员准备奠盒仪式。' })
        var startDate = this.$moment(this.$moment().format('YYYY-MM-DD HH:mm'), 'YYYY-MM-DD HH:mm')
        this.form.setFieldsValue({ start_at: startDate, end_at: startDate })
      })
    },
    handlePhoneNumberChange(e) {
      this.miniUserOptions.forEach((option) => {
        if (option.id === e) {
          this.form.setFieldsValue({ phone_number: option.phone_number })
        }
      })
    },
    fetchUserOptions() {
      this.loadingUserOptions = true
      findTenantTaskUserOptions({ task_type: 'libation_box', order_type: 'service', order_id: this.serviceOrderId }).then((res) => {
        if (res.code === 0) {
          this.userOptions = res.data.option_list
          this.defaultUser = res.data.default_user
          this.form.setFieldsValue({ phone_number: this.defaultUser.phone_number })
        }
        this.loadingUserOptions = false
      })
    },
    fetchMiniUserOptions() {
      this.loadingUserOptions = true
      findFuneralHomeOptions({ destination_id: this.funeralHomeId }).then((res) => {
        if (res.code === 0) {
          this.miniUserOptions = res.data
        }
        this.loadingUserOptions = false
      })
    },
    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }
      this.form.validateFields((err, values) => {
        if (!err) {
          this.submitting = true
          const fieldsValue = this.form.getFieldsValue()
          const values = {
            ...fieldsValue,
            begin_time: formatDateTimeMin(fieldsValue.start_at),
            end_time: formatDateTimeMin(fieldsValue.end_at)
          }
          sendLibationBoxCeremonyNotice(this.serviceOrderId, values).then((res) => {
            if (res.code === 0) {
              // 关闭模态框
              this.isShow = false
              // 告知父组件已完成
              this.$emit('completed')
            }
            this.submitting = false
          })
        }
      })
    }
  }
}
</script>
